/* *****************************************
 * RedactorX
 * https://imperavi.com/redactorx/docs/
 * *****************************************/

import '../../../vendor/redactorx/redactorx';

// Plugins
// https://imperavi.com/redactorx/plugins/
import '../../../vendor/redactorx/plugins/alignment/alignment';
import '../../../vendor/redactorx/plugins/imageresize/imageresize';
import '../../../vendor/redactorx/plugins/imageposition/imageposition';

import './extended-context';

// Style and translations
import '../../../vendor/redactorx/redactorx.min.css';
import './custom-style';
import './fr';

// General settings for all editors
// https://imperavi.com/redactorx/docs/settings/
RedactorX.settings = {
  source: false, // disable switching to HTML edit mode
  shortcutsRemove: ['ctrl+shift+up, meta+shift+up', 'ctrl+shift+down, meta+shift+down'],
  buttons: {
    topbar: ['undo', 'redo'],
  },
  editor: {
    lang: 'fr',
    minHeight: '100px',
    maxHeight: '800px',
  },
  link: {
    size: 60, // shorten links after 60 chars
  },
  paste: {
    formTags: [], // prevent buttons and inputs to be pasted in content
  },
  classes: {
    img: 'img-fluid',
  },
  subscribe: {
    'editor.blur': function(e) {
      var textarea = getTextareaBehindRedactor(this);
      $(textarea).triggerHandler('focusout'); // trigger 'focusout' for annotate component

      var form = getFormBehindRedactor(this);
      if (form != undefined) {
        form.dispatchEvent(new CustomEvent('change')); // trigger 'change' for autosave component
      }
    },
    'editor.focus': function(e) {
      var textarea = getTextareaBehindRedactor(this);
      $(textarea).triggerHandler('focus'); // trigger 'focus' for annotate component
    },
    'editor.change': function(html) {
      var textarea = getTextareaBehindRedactor(this);
      textarea.dispatchEvent(new CustomEvent('keyup')); // trigger 'keyup' for chars counter
    },
    'upload.before.send': function(e) {
      var csrfToken = $('meta[name=csrf-token]').attr('content');
      var xhr = e.get('xhr');
      xhr.setRequestHeader('X-CSRF-Token', csrfToken);
    },
  },
};

$(function() {
  activateRedactorx();
  window.addEventListener('neo:complete', activateRedactorx);
  window.addEventListener('redactorx:refresh', activateRedactorx);
});

function activateRedactorx() {
  // Use for admin notes where it's ok to edit the HTML source directly
  RedactorX('.redactorx-html', {
    source: true,
    plugins: ['alignment'],
  });

  // Use for public texts, like in emailing, workflow actions, etc
  RedactorX('.redactorx-simple', {
    addbar: false,
    format: ['p', 'h3', 'ul', 'ol'],
    plugins: ['alignment'],
  });

  // Use for internal texts, like CRM notes, briefs, etc
  RedactorX('.redactorx-crm', {
    addbar: false,
    format: ['p', 'h3', 'ul', 'ol'],
    plugins: ['alignment'],
    formatAdd: extraFormatCommands,
  });

  // Use for texts where you need to insert images
  RedactorX('.redactorx-img', {
    buttons: {
      addbar: ['paragraph', 'image'],
    },
    image: {
      upload: '/admin/redactor_rails/asset_uploads/',
      data: userIdForAdminImpersonation(),
      drop: true,
      width: true,
    },
    format: ['p', 'h3', 'ul', 'ol'],
    plugins: ['alignment', 'imageresize', 'imageposition'],
  });

  // Light editor popping up on selected text
  RedactorX('.redactorx-air:enabled', {
    addbar: false,
    toolbar: false,
    topbar: false,
    context: true,
    buttons: {
      context: ['bold', 'italic', 'deleted', 'mark', 'link'],
    },
    plugins: ['extended-context'],
  });
}

var extraFormatCommands = {
  lead: {
    title: '<strong>Important</strong>',
    type: 'paragraph',
    params: {
      tag: 'p',
      classname: 'lead',
    },
  },
  red: {
    title: '<span style="color: #F93943;">Rouge</span>',
    type: 'paragraph',
    params: {
      tag: 'p',
      classname: 'red',
    },
  },
  quote: {
    title: '<i>Citation</i>',
    type: 'paragraph',
    params: {
      tag: 'p',
      classname: 'quote',
    },
  },
  note: {
    title: '<span style="padding: 6px 8px; background-color: #FEF2C3; color: #645F4A;">Note</span>',
    type: 'paragraph',
    params: {
      tag: 'p',
      classname: 'note',
    },
  },
};

function getTextareaBehindRedactor(editor) {
  // This seems to work as well but is not documented
  // return editor.app.$element.nodes[0];
  return document.getElementById(editor.app.$element.attr('id'));
}

function getFormBehindRedactor(editor) {
  var textarea = getTextareaBehindRedactor(editor);
  return textarea.closest('form[class*=autosave]');
}

function userIdForAdminImpersonation() {
  var params = new URL(document.location).searchParams;
  var userId = params.get('user_id');
  if (userId) {
    return { user_id: userId };
  } else {
    return;
  }
}
